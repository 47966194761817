import 'slick-carousel/slick/slick.css';
import '../styles/Accordion.scss';
import '../styles/AriaLiveRegion.scss';
import '../styles/BlockWrapper.scss';
import '../styles/BlockWrapperWithImage.scss';
import '../styles/Breadcrumbs.scss';
import '../styles/BuyNowButton.scss';
import '../styles/Carousel.scss';
import '../styles/CarouselArrow.scss';
import '../styles/Checkbox.scss';
import '../styles/CollectionBanner.scss';
import '../styles/ContactUs.scss';
import '../styles/ConfirmModal.scss';
import '../styles/CountrySelector.scss';
import '../styles/ErrorPage.scss';
import '../styles/Filters.scss';
import '../styles/Footer.scss';
import '../styles/GlossaryTable.scss';
import '../styles/HairQuiz.scss';
import '../styles/HamburgerMenu.scss';
import '../styles/Header.scss';
import '../styles/IngredientsBlockContainer.scss';
import '../styles/IngredientsCarousel.scss';
import '../styles/Layout.scss';
import '../styles/LinkBlockContainer.scss';
import '../styles/Loading.scss';
import '../styles/Modal.scss';
import '../styles/Navigation.scss';
import '../styles/NavigationMobile.scss';
import '../styles/NixedAccordionHolder.scss';
import '../styles/NixedIngredients.scss';
import '../styles/Percent.scss';
import '../styles/PetaBlock.scss';
import '../styles/ProductCard.scss';
import '../styles/ProductHeader.scss';
import '../styles/ProductCarousel.scss';
import '../styles/ProductImages.scss';
import '../styles/ProductLineBanners.scss';
import '../styles/ProductListing.scss';
import '../styles/ProductListingHeader.scss';
import '../styles/Products.scss';
import '../styles/ProductStrengthsContainer.scss';
import '../styles/ProductVariants.scss';
import '../styles/Quiz.scss';
import '../styles/RatingStars.scss';
import '../styles/ReviewCard.scss';
import '../styles/Reviews.scss';
import '../styles/ReviewsFilter.scss';
import '../styles/SearchInput.scss';
import '../styles/SearchResults.scss';
import '../styles/ShareButton.scss';
import '../styles/Sitemap.scss';
import '../styles/StepInfoBlock.scss';
import '../styles/Subheader.scss';
import '../styles/TableInfoBlock.scss';
import '../styles/TextTable.scss';
import '../styles/VideoBlock.scss';
import '../styles/WriteReview.scss';
import '../styles/slick.scss';
import '../styles/TextInfoBlock.scss';
import '../styles/Collections.scss';
import '../styles/CollectionCarousel.scss';
import '../styles/LandingPageCardCarousel.scss';
import '../styles/card.scss';
import '../styles/landingPageBanner.scss';
import '../styles/StickyBanner.scss';

const MyApp = ({ Component, pageProps }) => {
    return <Component {...pageProps} />;
};

export default MyApp;
